import React from "react"
import 'rc-slider/assets/index.css';
import "../styles/global.css"


export default function notFound() {
  return <div>
    <p>Not found!</p>
  </div>
}
